import React, { useEffect, useState } from 'react';
import './board_stylesheets/SuccessfulCategoryTile.css';
import { CATEGORY_COLOR_DATA_MAP, DARK_COLORS_WHITE_TEXT } from '../../siteConstants';
import { getCurrentLeague } from '../../helperFunctions';

const current_league = getCurrentLeague();

const SuccessfulCategoryTile = (props) => {
    const { category_data, player_data, group, useTextMode } = props;
    const [groupId, setGroupId] = useState(0)

    const league_category_color_set = CATEGORY_COLOR_DATA_MAP[current_league];

    useEffect(() => {
        try {
            const first_player_data = player_data.find(player => player.PlayerId === group[0])
            setGroupId(first_player_data['GroupId']);
        }
        catch { }
        // eslint-disable-next-line
    }, [group])

    const renderPlayerImages = () => {
        const rows = []
        for (let i = 0; i < group.length; i++) {
            try {
                const player = player_data.find(player => player.PlayerId === group[i]);
                const playerElement = useTextMode
                    ?
                    <p className='player-name' style={{ color: DARK_COLORS_WHITE_TEXT.includes(league_category_color_set[groupId - 1]) ? 'white' : '#2C2C2C' }}>
                        {player['FirstName']} <br />{player['LastName']}
                    </p>
                    :
                    <img
                        className='successful-img'
                        key={player['PlayerId']}
                        draggable={false}
                        src={player['ImageSource']}
                        alt={player['FirstName']}
                    />

                rows.push(
                    <div className='player-tile'>
                        {playerElement}
                    </div>)
            }
            catch (e) {
                console.log('error', e)
            }
        }
        return rows
    }

    return (
        <>
            {
                groupId > 0 &&
                <div className='successful-card' style={{ backgroundColor: league_category_color_set[groupId - 1] }}>
                    <h2
                        className='success-title'
                        style={{ color: DARK_COLORS_WHITE_TEXT.includes(league_category_color_set[groupId - 1]) ? 'white' : '#2C2C2C' }}>
                        {category_data[groupId - 1]?.toUpperCase()}
                    </h2>
                    <div className='successful-img-container'>
                        {renderPlayerImages()}
                    </div>
                </div>
            }
        </>
    )
}


export default SuccessfulCategoryTile;