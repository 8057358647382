import React, { useState, useEffect, useRef } from 'react';
import './stylesheets/EndModal.css';
import Button from '../helpers/button';
import { useCopyToClipboard } from 'usehooks-ts'
import { END_MODAL_BACKGROUND_COLOR, COLOR_EMOJIS, CURRENT_LEAGUE_MAP, CURRENT_LEAGUE_NAME_MAP, CURRENT_LEAGUE_URL_MAP, CATEGORY_COLOR_DATA_MAP } from '../../siteConstants'
import { convertMillisToSeconds, getConsecutiveWinStreak, getCurrentLeague, getSolvedAndUnsolvedGroupsFromHistory } from '../../helperFunctions';

const current_league = getCurrentLeague();
const current_league_name = CURRENT_LEAGUE_MAP[current_league];
const league_title = CURRENT_LEAGUE_NAME_MAP[current_league];
const league_url_name = CURRENT_LEAGUE_URL_MAP[current_league];
const league_category_color_set = CATEGORY_COLOR_DATA_MAP[current_league];

const EndModal = (props) => {
    const { player_data, gameResult, history, setViewingResults, boardNumber, secondsTaken, switchBoard, setShowAnalyticsPanel, inHOF } = props;

    const btnRef = useRef(null);
    const [, copy] = useCopyToClipboard()
    const [historyString, setHistoryString] = useState(league_title)
    const [copyString, setCopyString] = useState(navigator.canShare ? 'Share 🔗' : 'Copy to Clipboard')
    const emoji_buffer = '          '
    const hof_emoji_buffer = '         '

    const [countdown, setCountdown] = useState(0);
    const [revealTime, setRevealTime] = useState(false);

    const todaysBoardNumber = Number(localStorage.getItem('todaysBoardNumber'))
    const consecutiveWinStreakNum = getConsecutiveWinStreak()

    const timeTakenString = () => {
        return convertMillisToSeconds(secondsTaken)
    }

    useEffect(() => {
        const calculateCountdown = () => {
            const now = new Date();
            const nextDay = new Date(now);
            nextDay.setUTCHours(0, 0, 0, 0);
            nextDay.setUTCDate(nextDay.getUTCDate() + 1);

            const timeUntilNextDay = nextDay.getTime() - now.getTime();
            setCountdown(timeUntilNextDay);
        };

        // Calculate initial countdown
        calculateCountdown();

        // Update countdown every second
        const intervalId = setInterval(() => {
            calculateCountdown();
        }, 1000);

        // Clean up interval on component unmount
        return () => clearInterval(intervalId);
    }, []);

    useEffect(() => {
        if (revealTime) {
            window.gtag('event', 'revealed_time', {
                'timeTaken': timeTakenString()
            })
        }
        // eslint-disable-next-line
    }, [revealTime])

    // Convert milliseconds to hours, minutes, and seconds
    const hours = Math.floor((countdown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((countdown % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((countdown % (1000 * 60)) / 1000);

    const copyToClip = () => {
        setCopyString('Copied!')
        setTimeout(() => setCopyString('Copy to Clipboard'), 2000);
        copy(historyString)
    }

    const getGroupId = (playerId) => {
        try {
            const get_player_info = player_data.filter((player) => Number(player['PlayerId']) === Number(playerId))
            return get_player_info[0]['GroupId'];
        }
        catch {
            return 1;
        }
    }

    const getColorSet = (playerId) => {
        return league_category_color_set[getGroupId(playerId) - 1];
    }

    useEffect(() => {
        const clickedOutside = (e) => {
            if (e.srcElement.className === 'end-modal') setViewingResults()
        }
        document.body.addEventListener('click', clickedOutside)

        const createHistoryString = () => {
            let historyString = `${league_title}\n`
            historyString += `Board #${boardNumber}\n`

            for (let i = 0; i < history.length; i++) {
                if (player_data.filter((player) => player['PlayerId'] === history[i][0])[0] === undefined) continue;
                let historyLineString = ''

                for (let j = 0; j < history[i].length; j++) {
                    const playerId = history[i][j]
                    const colorSet = getColorSet(playerId);
                    historyLineString = historyLineString + COLOR_EMOJIS[colorSet]
                }
                historyString = historyString + '\n' + emoji_buffer + historyLineString;
            }
            historyString = historyString + '\n'
            if (gameResult === 'win') historyString = historyString + '\nWin Streak: ' + consecutiveWinStreakNum + '🔥'
            historyString = historyString + `\n${league_url_name}`;

            return historyString
        }

        const createHOFHistoryString = () => {
            let historyString = `${league_title}\n`
            historyString += `Hall of Fame Board #${boardNumber}\n`

            const { solvedGroups, unsolvedGroups } = getSolvedAndUnsolvedGroupsFromHistory(history, player_data)

            for (let i = 0; i < solvedGroups.length; i++) {
                const colorSet = league_category_color_set[solvedGroups[i] - 1];
                historyString = historyString + '\n' + hof_emoji_buffer + COLOR_EMOJIS[colorSet].repeat(5) + ' ✅'
            }

            for (let i = 0; i < unsolvedGroups.length; i++) {
                const colorSet = league_category_color_set[unsolvedGroups[i] - 1];
                historyString = historyString + '\n' + hof_emoji_buffer + COLOR_EMOJIS[colorSet].repeat(5) + ' ❌'
            }

            historyString = historyString + '\n'
            if (gameResult === 'win') historyString = historyString + '\nWin Streak: ' + consecutiveWinStreakNum + '🔥'
            historyString = historyString + `\n${league_url_name + '/hof'}`;
            return historyString
        }
        // createHOFHistoryString()
        setHistoryString(inHOF ? createHOFHistoryString() : createHistoryString())
        return () => document.body.removeEventListener('click', clickedOutside)
        // eslint-disable-next-line
    }, [setViewingResults, history, player_data, boardNumber])

    const renderHistory = () => {
        const rows = []
        for (let i = 0; i < history.length; i++) {

            if (player_data.filter((player) => player['PlayerId'] === history[i][0])[0] === undefined) continue;
            rows.push(
                <div key={i} style={{ display: 'flex', flexDirection: 'row', marginBottom: '0.3em', justifyContent: 'center' }}>
                    {history[i].map((playerId) => (
                        <div
                            key={playerId}
                            style={{
                                width: '4.7vh',
                                height: '4.7vh',
                                background: getColorSet(playerId),
                                marginRight: '1px',
                                marginLeft: '1px',
                                borderRadius: '5px'
                            }} />
                    ))
                    }
                </div>
            )
        }
        return rows
    }

    const renderHOFHistory = () => {
        const { solvedGroups, unsolvedGroups } = getSolvedAndUnsolvedGroupsFromHistory(history, player_data)

        let rows = [<p className='end-modal-text'>Total Guesses: {history.length}</p>];
        if (solvedGroups.length > 0) {
            rows.push(<p className='end-modal-text'>Your Successful Guesses:</p>)
            for (let i = 0; i < solvedGroups.length; i++) {
                rows.push(
                    <div key={i} style={{ display: 'flex', flexDirection: 'row', marginBottom: '0.3em', justifyContent: 'center', alignItems: 'center' }}>
                        {Array(5).fill(true).map((_, indx) => (
                            <div
                                // eslint-disable-next-line
                                key={indx}
                                style={{
                                    width: '4.7vh',
                                    height: '4.7vh',
                                    background: league_category_color_set[solvedGroups[i] - 1],
                                    marginRight: '1px',
                                    marginLeft: '1px',
                                    borderRadius: '5px'
                                }} />
                        ))
                        }
                    </div>
                )
            }
        }


        if (unsolvedGroups.length > 0) {
            rows.push(<p className='end-modal-text'>Your Unsuccessful Guesses:</p>)
            for (let i = 0; i < unsolvedGroups.length; i++) {
                rows.push(
                    <div key={i} style={{ display: 'flex', flexDirection: 'row', marginBottom: '0.3em', justifyContent: 'center', alignItems: 'center' }}>
                        {Array(5).fill(true).map((_, indx) => (
                            <div
                                key={indx}
                                style={{
                                    width: '4.7vh',
                                    height: '4.7vh',
                                    background: league_category_color_set[unsolvedGroups[i] - 1],
                                    marginRight: '1px',
                                    marginLeft: '1px',
                                    borderRadius: '5px'
                                }} />
                        ))
                        }
                    </div>
                )
            }
        }
        return rows
    }

    const clickShareResults = () => {
        window.gtag('event', 'share_or_copy_board_results', {
            'result': gameResult,
            'winStreak': consecutiveWinStreakNum
        })
        try {
            if (navigator.canShare) {
                const shareData = {
                    text: historyString,
                };
                navigator.share(shareData);
            }
            else {
                copyToClip();
            }
        }
        catch {
            copyToClip();
        }
    }

    return (
        <div ref={btnRef} className='end-modal'>
            <div className='end-modal-content' style={{ backgroundColor: END_MODAL_BACKGROUND_COLOR }}>
                <h2 className='end-modal-title'>
                    {gameResult === 'win' ? "Good Job!" : "Nice Try!"}
                </h2>
                <h2 className='end-modal-text'>
                    {league_title} {inHOF && 'HOF '}#{boardNumber}
                </h2>

                {
                    revealTime ?
                        <div className='time-taken-container'>
                            <h2 className='end-modal-text'>
                                Time Taken:
                            </h2>
                            <h2 className='end-modal-text' style={{ color: 'lightgreen', marginLeft: '0.3em' }}>
                                {timeTakenString()}s
                            </h2>
                        </div>
                        :
                        <Button title={'Click to Reveal Time'} textStyle={{ fontSize: '1em' }} func={() => setRevealTime(true)} />
                }


                {!inHOF && <Button title={'View Your Stats'} style={{ marginTop: '1em' }} textStyle={{ fontSize: '1em' }} func={() => { setShowAnalyticsPanel(true); setViewingResults() }} />}

                {inHOF ?
                    renderHOFHistory()
                    :
                    <>
                        <p className='end-modal-text'>Your Results:</p>
                        {renderHistory()}
                    </>
                }

                {inHOF ?
                    <div className='next-board-container'>
                        <h2 className='next-board-top-text'>{'Next Board: THURSDAY 8PM EST'}</h2>
                    </div>

                    :
                    <div className='next-board-container'>
                        <h2 className='next-board-top-text'>{'NEXT BOARD IN:  '}</h2>
                        <p className='next-board-bottom-text'>
                            {hours.toString().padStart(2, '0')}:{minutes.toString().padStart(2, '0')}:{seconds.toString().padStart(2, '0')}
                        </p>
                    </div>
                }

                <Button style={{ margin: 0, marginTop: '0.5vh' }} title={copyString} func={() => clickShareResults()} />
                {
                    !inHOF && current_league_name !== 'NFL' &&
                    <div style={{ margin: '0', marginBlock: '1em' }}>
                        <h2 className='end-modal-text'>Need a challenge? Try our new 5x5 HOF game mode!</h2>
                        <Button style={{ width: '15em', margin: 0, marginTop: '.5vh', backgroundColor: '#2b59ff', border: 'none' }}
                            textStyle={{ color: 'white' }}
                            title={'Play HOF'}
                            func={() => { window.open('/hof', '_blank', 'rel=noopener noreferrer') }} />

                    </div>
                }
                {

                    current_league_name === 'NFL' &&
                    <div style={{ margin: '0', marginBlock: '1em' }}>
                        <h2 className='end-modal-text'>Try our original NBA mode!</h2>
                        <a href="https://www.leconnections.app" target="_blank" rel="noopener noreferrer">
                            <Button style={{ width: '15em', margin: 0, marginTop: '.5vh', backgroundColor: '#2b59ff', border: 'none' }}
                                textStyle={{ color: 'white' }}
                                title={'Play LeConnections'}
                            />
                        </a>
                    </div>
                }
                {
                    !inHOF && current_league_name !== 'NFL' && <Button style={{ width: '15em', margin: 0, marginTop: '1.5vh', backgroundColor: '#2b59ff', border: 'none' }}
                        textStyle={{ color: 'white' }}
                        title={`Play ${todaysBoardNumber !== Number(boardNumber) ? 'Today' : 'Yesterday'}'s Board`}
                        func={() => todaysBoardNumber !== Number(boardNumber) ? switchBoard(todaysBoardNumber) : switchBoard(todaysBoardNumber - 1)} />
                }
            </div>
        </div>
    )
}


export default EndModal;