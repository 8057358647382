import React, { useState } from 'react';
import blackInfoIcon from '../assets/black_info_icon.png';
import analyticsIcon from '../assets/data-analytics.png'
import './stylesheets/mainHeader.css'
import { formatToReadabilityDate, getCurrentLeague, isWeekend } from '../helperFunctions';
import X_ICON from '../assets/x.png'
import { hasPlayedWeeksHOF } from '../services/state';
import { CURRENT_LEAGUE_NAME_MAP } from '../siteConstants';

const current_league = getCurrentLeague();
const league_title = CURRENT_LEAGUE_NAME_MAP[current_league];

const Tooltip = (props) => {
    const { text, setShowToolTip } = props;

    return (
        <div className="tooltipcontainer">
            <div className="tooltipsubcontainer">
                <span className="tooltiptext">
                    {text}
                </span>
                <button className='close-button' style={{ paddingRight: '0' }} onClick={() => setShowToolTip(false)}>
                    <img className='close-icon-tooltip' src={X_ICON} alt='close'></img>
                </button>
            </div>
        </div>
    )
}

const Header = (props) => {
    const { boardNumber, switchBoard, setStartModal, setShowAnalyticsPanel, disablePlayOtherBoard, inHOF, setShowBoardSelector, playDate } = props;
    const todaysBoardNumber = Number(localStorage.getItem('todaysBoardNumber'))

    const HOFWeekend = isWeekend()
    const HasPlayedWeeksHOF = hasPlayedWeeksHOF()

    const [showToolTip, setShowToolTip] = useState(HOFWeekend && !HasPlayedWeeksHOF)

    return (
        <div className='header' style={{ color: inHOF ? 'white' : 'black' }}>
            <div className='header-sub'>
                <div
                    className='title-header-container'
                    onClick={() => window.open('/', '_blank', 'rel=noopener noreferrer')}
                >
                    <h2 className='main-title'>{league_title}</h2>
                </div>
                <div className='board-number-container'>
                    <h3 style={{ margin: 0 }}>{inHOF && ' Hall Of Fame'} #{boardNumber}</h3>
                    {
                        inHOF || disablePlayOtherBoard || todaysBoardNumber <= 1 ?
                            <div />
                            :
                            <button className='yesterday-button' onClick={() => { todaysBoardNumber !== Number(boardNumber) ? switchBoard(todaysBoardNumber) : switchBoard(todaysBoardNumber - 1) }}>
                                <p className='yesterday-button-text'>Play {todaysBoardNumber !== Number(boardNumber) ? 'Today' : 'Yesterday'}'s Board</p>
                            </button>
                    }
                </div>
            </div>
            <div className='header-sub'>
                <div className='date-container'>
                    <h3 className='format-date' style={{ color: current_league === 'nfl' && !inHOF && 'black' }}>{formatToReadabilityDate(playDate)}</h3>
                    {!inHOF &&
                        <img
                            onClick={() => setShowAnalyticsPanel(true)}
                            className='info-icon'
                            draggable={false}
                            src={analyticsIcon}
                            alt="info"
                        />
                    }
                    <img
                        className={`info-icon ${inHOF && 'invert-100-percent'}`}
                        onClick={() => setStartModal(true)}
                        draggable={false}
                        src={blackInfoIcon}
                        alt="info"
                    />
                </div>
                {
                    inHOF ?
                        <>
                            <div class="tooltip">

                                <button
                                    class="button-54"
                                    onClick={() => setShowBoardSelector(true)}
                                    style={{ filter: 'invert(100%)' }}
                                >
                                    All boards
                                </button>
                            </div>
                        </>
                        :
                        <>
                            <div className="tooltip">
                                <button
                                    className="button-54"
                                    onClick={() => window.open('/hof', '_blank', 'rel=noopener noreferrer')}
                                >
                                    PLAY HOF
                                </button>
                                {showToolTip &&
                                    <Tooltip text='New Board Drop!' setShowToolTip={setShowToolTip} />
                                }
                            </div>
                        </>

                }
            </div>
        </div >
    )
}

export default Header;
