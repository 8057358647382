import React from 'react';
import '../stylesheets/Ads.css'
import FreestarAdSlot from '@freestar/pubfig-adslot-react-component'

const VerticalAd = () => {
    return (
        <div className='vertical-ad-container'>
            <FreestarAdSlot
                publisher={'leconnections-app'}
                placementName={'leconnections_sidewall_left'}
            />
        </div>

    );
};

export default VerticalAd;