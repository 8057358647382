import React, { useEffect, useState, useRef } from 'react';
import './App.css';
import Main from './components/board/main';
import { checkUserPasscode, getBoardAndGroup, getBoardByNumber, getHOFBoardOfTheWeek, getTodaysBoardNumber } from './services/api';
import { gameResultTypes, getCurrentLeague, timeout } from './helperFunctions';
import { getGameDataProperty, initializeCurrentBoardNumber, removeGivenBoardData, setCurrentGameData } from './services/state';
import InstructionModal from './components/modals/InstructionModal';
import Footer from './components/Footer';
import { CURRENT_LEAGUE_FAVICON_MAP, CURRENT_LEAGUE_MAP, CURRENT_LEAGUE_NAME_MAP, LEAGUE_GOAT_MAP, MAIN_BACKGROUND_COLOR_MAP } from './siteConstants';
import LoadingScreen from './components/LoadingScreen';
import AnalyticsModal from './components/analytics/AnalyticsModal';
import VerticalAd from './components/ads/Freestar/VerticalAd';
import VerticalAd2 from './components/ads/Freestar/VerticalAd2';
import HorizontalAd1 from './components/ads/Freestar/HorizontalAd';
import SmallAnnoucementModal from './components/modals/SmallAnnoucementModal';
import BetaUserPage from './components/helpers/BetaUserPage';
import InstructionModalHOF from './components/modals/InstructionModalHOF';
import BigAnnouncementModal from './components/modals/BigAnnouncementModal';
import BoardSelectorModal from './components/modals/BoardSelector';
import Favicon from "react-favicon";
import { Helmet } from 'react-helmet';

const current_league = getCurrentLeague();
const current_league_name = CURRENT_LEAGUE_MAP[current_league];
const league_title = CURRENT_LEAGUE_NAME_MAP[current_league];
const league_favicon = CURRENT_LEAGUE_FAVICON_MAP[current_league];

function App(props) {
  const { basicData, inHOF } = props

  const [boardNumber, setBoardNumber] = useState(localStorage.getItem('boardNumber') ? Number(localStorage.getItem('boardNumber')) : -1)
  const [hofBoardNumber, setHOFBoardNumber] = useState(localStorage.getItem('HOFBoardNumber') ? Number(localStorage.getItem('HOFBoardNumber')) : -1)
  const [boardData, setBoardData] = useState([])
  const [categoryData, setCategoryData] = useState([]);
  const [playDate, setPlayDate] = useState(null);

  const [showSmallAnnoucementModal, setSmallAnnoucementModal] = useState(false)
  const [showBigAnnouncement, setShowBigAnnouncement] = useState(false)
  const [showStartModal, setStartModal] = useState((basicData.isNewUser && (getGameDataProperty(boardNumber, 'gameResult') === null || getGameDataProperty(boardNumber, 'gameResult') === gameResultTypes.playing)) || inHOF)
  const [showAnalyticsPanel, setShowAnalyticsPanel] = useState(false)
  const [viewingResults, setViewingResults] = useState(false);

  const [showBoardSelector, setShowBoardSelector] = useState(false);

  const refContainer = useRef(null);
  const [boardDataGlobal, setBoardDataGlobal] = useState({});
  const [boardStatsGlobal, setBoardStatsGlobal] = useState({});

  const [width, setWidth] = useState(window.innerWidth);
  localStorage.setItem('inHOF', inHOF.toString())

  //For HOF BETA
  const [passcode, setPassCode] = useState("");
  const [invalidPasscode, setInvalidPasscode] = useState(false)
  const [validUser, setValiduser] = useState(localStorage.getItem('validHOFUser') === 'true' ? true : false)
  const [clicked, setClicked] = useState(false)


  const isMobile = () => {
    const handleWindowSizeChange = () => {
      setWidth(window.innerWidth);
    }
    window.addEventListener('resize', handleWindowSizeChange);
    return (width <= 768);
  }
  useEffect(() => {
    document.title = league_title;
    const getBoard = async () => {
      const todaysBoardNum = Number(await getTodaysBoardNumber(false))
      const latestHOFBoardNum = Number(await getTodaysBoardNumber(true))

      localStorage.setItem('todaysBoardNumber', todaysBoardNum)
      localStorage.setItem('weeksHOFBoardNumber', latestHOFBoardNum)

      if (inHOF) {
        if (hofBoardNumber !== latestHOFBoardNum) {
          await callGivenBoardNum(boardNumber)
        }
        else {
          callGivenBoardNum(latestHOFBoardNum)
        }
      }
      else if (todaysBoardNum === boardNumber + 1) {
        // setStartModal(false)
        await timeout(200)
        await callGivenBoardNum(boardNumber)
      } else {
        // removeOldBoardData(todaysBoardNum)
        await timeout(600)
        await callTodaysBoard()
      }
    }
    getBoard()

    if (!inHOF)
      refContainer.current.scrollIntoView({ behavior: "smooth" }) // Scroll a bit to see footer
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (showAnalyticsPanel === true) {
      window.gtag('event', 'viewed_analytics_tab', {
        'userAgent': basicData.userAgent,
        'userBrowser': basicData.userBrowser
      })
    }
    // eslint-disable-next-line
  }, [showAnalyticsPanel])

  const switchBoard = async (selectedBoardNumber) => {
    localStorage.setItem('HOFBoardNumber', selectedBoardNumber);
    localStorage.setItem('boardNumber', selectedBoardNumber)

    if (Number(localStorage.getItem('todaysBoardNumber')) === selectedBoardNumber) {
      window.gtag('event', 'clicked_todays_board', {
        'userAgent': basicData.userAgent,
        'userBrowser': basicData.userBrowser
      })
    }
    else {
      window.gtag('event', 'clicked_yesterdays_board', {
        'userAgent': basicData.userAgent,
        'userBrowser': basicData.userBrowser
      })
    }
    window.location.reload()
  }

  const newUpdateGivenBoard = (newbn) => {
    removeGivenBoardData(newbn)
    callGivenBoardNum(newbn)
    window.location.reload()
  }

  const parseBoardResponseData = (response) => {
    const newBoardNum = response['BoardNumber']
    const newBoardData = response['Players']
    const newCategoryData = response['Categories']
    const newUpdatedAt = response['UpdatedAt']
    const newPlayDate = response['PlayDate']

    if (inHOF) localStorage.setItem('HOFBoardNumber', newBoardNum)
    else localStorage.setItem('boardNumber', newBoardNum)

    initializeCurrentBoardNumber(newBoardNum)

    // Update the board data global with board and category data
    const newBoardDataGlobal = {
      [newBoardNum]: {
        categoryData: newCategoryData,
        boardData: newBoardData
      }
    }
    setBoardDataGlobal({
      ...boardDataGlobal,
      ...newBoardDataGlobal
    })

    setCurrentGameData(newBoardNum, 'updatedAt', newUpdatedAt)

    setBoardData(newBoardData)
    setCategoryData(newCategoryData)
    setPlayDate(newPlayDate)

    if (inHOF) {
      setHOFBoardNumber(newBoardNum)
      setBoardNumber(newBoardNum)
    }
    else setBoardNumber(newBoardNum)
  }

  const callTodaysBoard = async () => {
    const response = inHOF ? await getHOFBoardOfTheWeek() : await getBoardAndGroup();
    parseBoardResponseData(response)
  }

  const callGivenBoardNum = async (newBoardNum) => {
    const response = inHOF ? await getBoardByNumber(true, newBoardNum) : await getBoardByNumber(false, newBoardNum)
    parseBoardResponseData(response)
  }

  const checkPasscode = async () => {
    setClicked(true)
    try {
      const response = await checkUserPasscode(passcode)

      if (response.status !== 200) {
        setInvalidPasscode(true)
        setTimeout(() => setInvalidPasscode(false), 2000);
      }
      else {
        setValiduser(true)
        localStorage.setItem('validHOFUser', true)
      }
    }
    catch {
      setInvalidPasscode(true)
      setTimeout(() => setInvalidPasscode(false), 2000);
    }
    setClicked(false)
  }

  return (
    <div
      className='master-container'
      style={{ backgroundColor: MAIN_BACKGROUND_COLOR_MAP[inHOF ? 'hof' : current_league] }}
    >
      <Favicon url={league_favicon} />
      <Helmet>
        <title>{league_title}</title>
        <meta name="title" content={league_title} />
        <link rel="apple-touch-icon" href={league_favicon} />
      </Helmet>

      {showSmallAnnoucementModal && <SmallAnnoucementModal setSmallAnnoucementModal={setSmallAnnoucementModal} />}
      {isMobile() && <HorizontalAd1 />}

      <div className='sub-container'>
        {!isMobile() && <VerticalAd />}
        <div className='main-app-container'>
          <div />
          {showBigAnnouncement && !inHOF &&
            <BigAnnouncementModal setStartModal={setShowBigAnnouncement} />
          }
          {
            showStartModal && !showBigAnnouncement &&
            (inHOF ?
              <InstructionModalHOF setStartModal={setStartModal} /> :
              <InstructionModal setStartModal={setStartModal} />)
          }
          {
            showAnalyticsPanel &&
            <AnalyticsModal
              boardNumber={boardNumber}
              setViewingResults={setViewingResults}
              setShowAnalyticsPanel={setShowAnalyticsPanel}
              boardStatsGlobal={boardStatsGlobal}
              setBoardStatsGlobal={setBoardStatsGlobal}
              switchBoard={switchBoard}
            />
          }
          {
            showBoardSelector &&
            <BoardSelectorModal
              setShowBoardSelector={setShowBoardSelector}
              switchBoard={switchBoard}
              inHOF={inHOF}
            />
          }

          {
            !validUser && inHOF && false
              ?
              <div ref={refContainer} style={{ width: '100%', display: 'flex', flexDirection: 'column', height: '100vh', justifyContent: 'center' }}>
                <BetaUserPage clicked={clicked} value={passcode} onChange={(e) => setPassCode(e.target.value)} onSubmit={() => checkPasscode(passcode)} invalidPasscode={invalidPasscode} />
              </div>
              :
              <div style={{ minHeight: '80vh', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }} ref={refContainer}>
                {
                  (boardData.length >= 16 &&
                    categoryData.length >= 4 &&
                    ((!inHOF && boardNumber > 0) || (inHOF && hofBoardNumber))) ?

                    current_league === 'nba' ?
                      <Main
                        basicData={basicData}
                        showStartModal={showStartModal}
                        setStartModal={setStartModal}
                        viewingResults={viewingResults}
                        setViewingResults={setViewingResults}
                        setShowAnalyticsPanel={setShowAnalyticsPanel}
                        boardStatsGlobal={boardStatsGlobal}
                        setBoardStatsGlobal={setBoardStatsGlobal}
                        boardNumber={boardNumber}
                        switchBoard={switchBoard}
                        player_data={boardData}
                        category_data={categoryData}
                        newUpdateGivenBoard={newUpdateGivenBoard}
                        setShowBoardSelector={setShowBoardSelector}
                        playDate={playDate}
                      />
                      :
                      <p>Due to unforeseen technical difficulties, we are regrettably discontinuing this service.
                        <br />
                        Please let us know if you have any questions or concerns.
                        <br />
                        Thank you for your understanding.
                        <br />
                        -LeConnections</p>
                    :
                    <LoadingScreen text={`Texting ${LEAGUE_GOAT_MAP[current_league]} for Connections...`} inHOF={inHOF} />
                }
              </div>
          }
        </div>
        {!isMobile() && <VerticalAd2 />}
      </div>
      <Footer inHOF={inHOF} />

    </div>
  );
}

export default App;
