import * as CryptoJS from 'crypto-js'; // Import the CryptoJS library
import { v4 as uuid } from "uuid";
import { getBoardOfTheDayUpdatedAt } from './services/api';
import { getCurrentGameData, getGameDataProperty, removeGivenBoardData, removeGivenBoardDataProperty } from './services/state';

const current_league_env = process.env.REACT_APP_CURRENT_LEAGUE_ID

export const gameResultTypes = {
    playing: 'playing',
    win: 'win',
    lost: 'lost',
};

export const findRemainingGroups = (successfulGroups, player_data) => {
    const groups_solved = []
    if ( successfulGroups === null || successfulGroups === undefined || player_data === null) return 

    for (let i = 0; i < successfulGroups.length; i++) {
        groups_solved.push(player_data.find(player => player.PlayerId === successfulGroups[i][0])['GroupId'])
    }
    return groups_solved;
}

export const timeout = async (delay) => {
    return new Promise((res) => setTimeout(res, delay));
};

export const serializedHistory = (history) => {
    let final_string = '';
    for (let i = 0; i < history.length; i++) {
        final_string += history[i].toString();
        final_string += i === history.length - 1 ? '' : '|';
    }
    return final_string;
};

export const formatToReadabilityDate = (dateString) => {
    const date = new Date(dateString);
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return date.toLocaleDateString('en-US', options);
}

export const getTodaysReadableDate = () => {
    const now = new Date().toISOString(); // Get current date and time in UTC
    const date = new Date(now); // Create a Date object from the UTC string
    const month = toLocaleUTCDateString(date, 'en-US', { month: 'long' })
    const day = date.getUTCDate()
    const year = date.getUTCFullYear(); // Get year number
    const formattedDate = `${month} ${day}, ${year}`
    return formattedDate
}

export const getDateOfBoardNum = (num) => {
    const now = new Date().toISOString(); // Get current date and time in UTC
    const date = new Date(now); // Create a Date object from the UTC string
    date.setDate(date.getDate() - num);
    const month = toLocaleUTCDateString(date, 'en-US', { month: 'long' })
    const day = date.getUTCDate()
    const year = date.getUTCFullYear(); // Get year number

    const formattedDate = `${month} ${day}, ${year}`
    return formattedDate
}

export function toLocaleUTCDateString(date, locales, options) {
    const timeDiff = date.getTimezoneOffset() * 60000;
    const adjustedDate = new Date(date.valueOf() + timeDiff);
    return adjustedDate.toLocaleDateString(locales, options);
}

export const getShuffledGrid = (playersRemaining) => {
    const shuffledSquares = [...playersRemaining];
    for (let i = shuffledSquares.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [shuffledSquares[i], shuffledSquares[j]] = [
            shuffledSquares[j],
            shuffledSquares[i],
        ];
    }
    return shuffledSquares
};

export const generateAnswerKey = (player_data) => {
    const player_data_length = player_data.length === 25 ? 5 : 4;
    let answer_key = [];

    for (let i = 0; i < player_data_length; i++) {
        let group_temp = [];
        for (let j = 0; j < player_data_length; j++) {
            group_temp.push(player_data[i * player_data_length + j]['PlayerId']);
        }
        answer_key.push(group_temp);
    }
    return answer_key;
};

export const getBrowser = (sUsrAg) => {
    if (sUsrAg.indexOf("Firefox") > -1) {
        return "Mozilla Firefox";
        // "Mozilla/5.0 (X11; Ubuntu; Linux x86_64; rv:61.0) Gecko/20100101 Firefox/61.0"
    } else if (sUsrAg.indexOf("SamsungBrowser") > -1) {
        return "Samsung Internet";
        // "Mozilla/5.0 (Linux; Android 9; SAMSUNG SM-G955F Build/PPR1.180610.011) AppleWebKit/537.36 (KHTML, like Gecko) SamsungBrowser/9.4 Chrome/67.0.3396.87 Mobile Safari/537.36
    } else if (sUsrAg.indexOf("Opera") > -1 || sUsrAg.indexOf("OPR") > -1) {
        return "Opera";
        // "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_14_0) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/70.0.3538.102 Safari/537.36 OPR/57.0.3098.106"
    } else if (sUsrAg.indexOf("Trident") > -1) {
        return "Microsoft Internet Explorer";
        // "Mozilla/5.0 (Windows NT 10.0; WOW64; Trident/7.0; .NET4.0C; .NET4.0E; Zoom 3.6.0; wbx 1.0.0; rv:11.0) like Gecko"
    } else if (sUsrAg.indexOf("Edge") > -1) {
        return "Microsoft Edge";
        // "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/58.0.3029.110 Safari/537.36 Edge/16.16299"
    } else if (sUsrAg.indexOf("Chrome") > -1) {
        return "Google Chrome or Chromium";
        // "Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Ubuntu Chromium/66.0.3359.181 Chrome/66.0.3359.181 Safari/537.36"
    } else if (sUsrAg.indexOf("Safari") > -1) {
        return "Apple Safari";
        // "Mozilla/5.0 (iPhone; CPU iPhone OS 11_4 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/11.0 Mobile/15E148 Safari/604.1 980x1306"
    } else {
        return "unknown";
    }
  }


export const encryptString = async(plainText, key) => {
   // Ensure key and plainText are valid strings
  if (!key || !plainText) {
    throw new Error('key and plainText must be valid strings');
  }

  // Key preparation
  const keyBytes = CryptoJS.enc.Utf8.parse(key);

  // IV Handling (assuming a constant IV of 16 zero bytes)
  const iv = CryptoJS.lib.WordArray.create(16);

  // Encryption using CryptoJS
  const ciphertext = CryptoJS.AES.encrypt(plainText, keyBytes, { iv: iv });

  // Return Base64-encoded ciphertext
  return ciphertext.toString();
}
  

export const decrypt = async(cipherText, key) => {
    // Ensure key and cipherText are valid strings
    if (!key || !cipherText) {
      throw new Error('key and cipherText must be valid strings');
    }
  
    // Key preparation
    const keyBytes = CryptoJS.enc.Utf8.parse(key);
  
    // IV handling (assuming a constant IV of 16 zero bytes)
    const iv = CryptoJS.lib.WordArray.create(16); // Create a 16-byte word array
  
    // Decryption using CryptoJS
    const cipherParams = CryptoJS.lib.CipherParams.create({
      ciphertext: CryptoJS.enc.Base64.parse(cipherText)
    });
    const decryptor = CryptoJS.AES.decrypt(cipherParams, keyBytes, { iv: iv,  padding: CryptoJS.pad.Pkcs7 });
    const decryptedText = decryptor.toString(CryptoJS.enc.Utf8);
  
    return decryptedText;
  }

export const generateNewUserId = () => {
    const unique_user_id = uuid();
    localStorage.setItem('userId', unique_user_id);
    return unique_user_id   
}

export const needToHardUpdate = async(boardNum, updatedAt) => {
    if (updatedAt === null || boardNum === null) return false
    const newUpdatedAt = await getBoardOfTheDayUpdatedAt(boardNum)

    if (updatedAt !== newUpdatedAt) {
      return true
    }
    return false
}

export const dateDifferenceInMiliSeconds = (dateInitial, dateFinal) =>
  dateFinal - dateInitial;

export const removeOldBoardData = (boardNum) => {
    if (getGameDataProperty(boardNum, 'boardData') !== null && getGameDataProperty(boardNum, 'categoryData') !== null) {
      removeGivenBoardDataProperty(boardNum, 'boardData')
      removeGivenBoardDataProperty(boardNum, 'categoryData')
    }
    else if (getGameDataProperty(boardNum, 'boardData') !== null || getGameDataProperty(boardNum, 'categoryData') !== null) {
      removeGivenBoardData(boardNum) // Just in case there's obsolete data, clear the board
    }
}

export const convertMillisToSeconds = (seconds) => {
    const str = seconds.toString().slice(0, -2);
    return str.slice(0, -1) + '.' + str[str.length - 1]
}

export const getGroupIdFromPlayerId = (playerId, player_data) => {
    try {
        const get_player_info = player_data.filter((player) => Number(player['PlayerId']) === Number(playerId))
        return get_player_info[0]['GroupId'];
    }
    catch {
        return 0;
    }
}

export const getSolvedAndUnsolvedGroupsFromHistory = (history, player_data) => {
    if (history === undefined) return []

    const solvedGroups = []

    for (let i = 0; i < history.length; i++) {
        if (player_data.filter((player) => player['PlayerId'] === history[i][0])[0] === undefined) continue;

        let checkGroupId = -1;
        let failed = false
        for (let j = 0; j < history[i].length; j++) {
            const playerId = history[i][j]

            if (checkGroupId === -1) {
                checkGroupId = getGroupIdFromPlayerId(playerId, player_data);
            } else {
                if (checkGroupId !== getGroupIdFromPlayerId(playerId, player_data)) {
                    failed = true;
                    break;
                }
            }
        }

        if (!failed) {
            solvedGroups.push(checkGroupId)
        }
    }

    const unsolvedGroups = Array.from({ length: 5 }, (_, index) => index + 1).filter(
        (group) => !solvedGroups.includes(group)
    );
    return {solvedGroups, unsolvedGroups}

}

export const getConsecutivePlayStreak = () => {
    const game_data = getCurrentGameData();
    const todaysBoardNumber = Number(localStorage.getItem('todaysBoardNumber'))
    let count = 0;

    for (let i = todaysBoardNumber-1; i > 0; i --) {
        if (i in game_data && 'gameResult' in game_data[i]) {
            count += 1;            
        }
        else {
            break
        }
    }

    return todaysBoardNumber in game_data && 'gameResult' in game_data[todaysBoardNumber]? count + 1: count
}

export const getWinPercentageAndTotalGamesPlayed = () => {
    const game_data = getCurrentGameData();
    const todaysBoardNumber = Number(localStorage.getItem('todaysBoardNumber'))
    let gamesPlayed = 0;
    let gamesWon = 0;


    for (let i = todaysBoardNumber; i > 0; i --) {
        if (i in game_data && 'gameResult' in game_data[i]) {
            gamesPlayed += 1;

            if ('win' === game_data[i]['gameResult'])
                gamesWon += 1;
        }
    }

    const gamesWonPercentage = gamesPlayed === 0 ? 0 : ((gamesWon/gamesPlayed) * 100).toFixed(1)
    return {gamesPlayed, gamesWonPercentage}
}

export const getConsecutiveWinStreak = () => {
    const game_data = getCurrentGameData();
    const todaysBoardNumber = Number(localStorage.getItem('todaysBoardNumber'))
    let count = 0;

    for (let i = todaysBoardNumber-1; i > 0; i --) {
        if (i in game_data && 'gameResult' in game_data[i] && 'win' === game_data[i]['gameResult']) {
            count += 1;            
        }
        else if (i in game_data && 'gameResult' in game_data[i] && 'lost' === game_data[i]['gameResult']) {
            break
        }
    }

    return (todaysBoardNumber in game_data && 'gameResult' in game_data[todaysBoardNumber] && 'playing' !== game_data[todaysBoardNumber]['gameResult']) ? 'lost' === game_data[todaysBoardNumber]['gameResult'] ? 0 : count + 1: count
}

export const isWeekend = () => {
    const now = new Date();
    const day = now.getUTCDay(); // Get current day (0 = Sunday, 1 = Monday, ..., 6 = Saturday)

    if ((day === 5 || day === 6 || day === 0 || day === 1)) {
      return true;
    }

    return false;
  }

export const getCurrentLeague = () => {
    // Get the current league, if it's not set, default to NBA
    return current_league_env ?? 'nba';
}

// export const leagueChoicerHelperFunction = (nbaVal, nflVal) => {
//     const current_league = getCurrentLeague();

//     switch (current_league) {
//         case 'nba':
//             return nbaVal
//         case 'nfl':
//             return nflVal
//         default:
//             return null
//     }
// }