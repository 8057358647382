import React from 'react';
import '../stylesheets/Ads.css'
import FreestarAdSlot from '@freestar/pubfig-adslot-react-component'

const HorizontalAd1 = () => {

    return (
        <div className='horizontal-ad-container'>
            <FreestarAdSlot
                publisher={'leconnections-app'}
                placementName={'leconnections_mobile_leaderboard_atf'}
            />
        </div>

    );
};

export default HorizontalAd1;