import React, { useEffect, useState, useRef } from 'react';
import { useSpring, animated } from '@react-spring/web'
import { HOF_PLAYER_TILE_COLOR_SELECTED, HOF_PLAYER_TILE_COLOR_UNSELECTED, PLAYER_TILE_COLOR_SELECTED_MAP, PLAYER_TILE_COLOR_UNSELECTED_MAP } from '../../siteConstants';
import './board_stylesheets/PlayerTile.css';
import { getCurrentLeague } from '../../helperFunctions';

const current_league = getCurrentLeague();

const PlayerTile = (props) => {
    const { cantSelect, selectFunction, playerInfo, selected, doShakeUp, doShakeSide, doGroupAnimation, groupNumber, useTextMode, inHOF } = props;
    const [shakeUp, setShakeUp] = useState(false);
    const [shakeSide, setShakeSide] = useState(false);
    const [validImage, setValidImage] = useState(false);
    const elementRef = useRef(null);
    const playerId = playerInfo['PlayerId']
    const playerImageSrc = playerInfo['ImageSource']
    const playerFirstName = playerInfo['FirstName']
    const playerLastName = playerInfo['LastName']

    const league_player_tile_selected = PLAYER_TILE_COLOR_SELECTED_MAP[current_league];
    const league_player_tile_unselected = PLAYER_TILE_COLOR_UNSELECTED_MAP[current_league];

    useEffect(() => {
        const verifyImage = async () => {
            const res = await checkImage(playerImageSrc)
            setValidImage(res)
        }
        verifyImage()
    }, [playerImageSrc])

    const updateNameSize = (name) => {
        if (name.length >= 11)
            return 1.2
        else if (name.length >= 10)
            return 1.3
        else if (name.length >= 9)
            return 1.4
        else if (name.length >= 8)
            return 1.5
        else if (name.length >= 7)
            return 1.6
        else if (name.length >= 6)
            return 1.7

        return 1.8
    }

    const checkImage = (url) => {
        const img = new Image();
        img.src = url;
        return new Promise((resolve) => {
            img.onload = () => resolve(true);
            img.onerror = () => resolve(false);
        });
    }

    const groupCoordinates = [
        {
            x: '380',
            y: '124'
        },
        {
            x: '380',
            y: '263.5'
        },
        {
            x: '380',
            y: '366'
        },
        {
            x: '380',
            y: '505.5'
        }
    ]

    const [spring, set] = useSpring(() => ({
        // Initial position
        xy: [0, 0],
        config: { duration: 1000 }, // Set the duration to 1000 milliseconds (1 second)
    }));

    useEffect(() => {
        if (doGroupAnimation) handleMove();
        // eslint-disable-next-line
    }, [doGroupAnimation])

    useEffect(() => {
        if (doShakeUp) handleShakeUp();
    }, [doShakeUp])

    useEffect(() => {
        if (doShakeSide) handleShakeSide();
    }, [doShakeSide])

    const handleMove = () => {
        if (elementRef.current) {
            const rect = elementRef.current.getBoundingClientRect();
            const x = rect.left;
            const y = rect.top;
            // Move the component to a new position
            set({
                xy: [groupCoordinates[groupNumber].x - x, groupCoordinates[groupNumber].y - y],
                immediate: false,
            }); // Use immediate: false to enable the animation
        }
    };

    const handleShakeUp = () => {
        setShakeUp(true);
        setTimeout(() => setShakeUp(false), 1000); // Set a timeout to stop shaking after 1 second
    };

    const handleShakeSide = () => {
        setShakeSide(true);
        setTimeout(() => setShakeSide(false), 1000); // Set a timeout to stop shaking after 1 second
    };

    const getBackgroundColor = () => {
        if (inHOF) {
            if (selected.includes(playerId)) {
                return HOF_PLAYER_TILE_COLOR_SELECTED;
            }
            return HOF_PLAYER_TILE_COLOR_UNSELECTED;
        }
        if (selected.includes(playerId)) {
            return league_player_tile_selected;
        }

        return league_player_tile_unselected;
    }
    return (
        <animated.div
            ref={elementRef}
            className={`card shaking-container ${shakeUp ? 'shakeUp' : shakeSide ? 'shakeSide' : ''} ${inHOF && 'card-hof'}`}
            style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                userSelect: 'none',
                backgroundColor: getBackgroundColor(),
                transform: spring.xy.to((x, y) => `translate(${x}px, ${y}px)`),
                padding: '0.5em'

            }}
            onClick={() => !cantSelect && selectFunction(playerId)}
        >
            {
                useTextMode || !validImage ?
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                        <p className='player-name' style={{ fontSize: `${Math.max(updateNameSize(playerFirstName), updateNameSize(playerLastName)) / 2.4}em` }}>
                            {playerFirstName}
                        </p>
                        <p className='player-name' style={{ fontSize: `${updateNameSize(playerLastName) / 2.4}em` }}>
                            {playerLastName}
                        </p>
                    </div>
                    :
                    <img
                        draggable={false}
                        className='card-img'
                        src={playerImageSrc}
                        alt="player"
                    />

            }
        </animated.div >
    )
}

export default PlayerTile;