import React, { useEffect, useState } from 'react';
import './board_stylesheets/main.css';
import PlayerTile from './PlayerTile';
import Button from '../helpers/button';
import EndModal from '../modals/EndModal';
import SuccessfulCategoryTile from './SuccessfulCategoryTile';
import { timeout, findRemainingGroups, serializedHistory, getShuffledGrid, generateAnswerKey, gameResultTypes, dateDifferenceInMiliSeconds, getCurrentLeague } from '../../helperFunctions';
import { CURRENT_LEAGUE_FAVICON_MAP, CURRENT_LEAGUE_LIFE_ICON_SIZE_MAP, LIVES_AMOUNT_MAIN } from '../../siteConstants';
import WarningModal from '../modals/WarningModal';
import { finalizeBoardAnswers, finalizeHOFBoardAnswers } from '../../services/api';
import 'react-toggle/style.css';
import Header from '../MainHeader';
import { getGameDataProperty, setCurrentGameData } from '../../services/state';
import UseNameToggle from './UseNameToggle';
import ShowBasicAnalytics from '../analytics/ShowAnalytics';
import HintsModal from './HintsModal';
import GiveUpModal from '../modals/GiveUpModal';

const current_league = getCurrentLeague();
const league_favicon = CURRENT_LEAGUE_FAVICON_MAP[current_league];
const league_life_icon_size = CURRENT_LEAGUE_LIFE_ICON_SIZE_MAP[current_league];

function Main(props) {
    const {
        basicData,
        showStartModal,
        setStartModal,
        viewingResults,
        setViewingResults,
        setShowAnalyticsPanel,
        boardStatsGlobal,
        setBoardStatsGlobal,
        boardNumber,
        switchBoard,
        player_data,
        category_data,
        setShowBoardSelector,
        playDate
    } = props;
    const {
        userId, userTimezone, userIpAddress, userAgent, userBrowser
    } = basicData

    const PLAYER_AMOUNT_PER_CATEGORY = category_data?.length;

    let [playersRemaining, setPlayersRemaining] = useState(
        getGameDataProperty(boardNumber, 'playersRemaining') === null ? player_data : JSON.parse(getGameDataProperty(boardNumber, 'playersRemaining'))
    );
    let [successfulGroups, setSuccessfulGroups] = useState(
        getGameDataProperty(boardNumber, 'successfulGroups') === null ? [] : JSON.parse(getGameDataProperty(boardNumber, 'successfulGroups'))
    );
    const [gameResult, setGameResult] = useState(
        getGameDataProperty(boardNumber, 'gameResult') === null ? gameResultTypes.playing : getGameDataProperty(boardNumber, 'gameResult')
    );
    const [lives, setLives] = useState(
        getGameDataProperty(boardNumber, 'lives') === null ? LIVES_AMOUNT_MAIN : Number(getGameDataProperty(boardNumber, 'lives'))
    );
    const [history, setHistory] = useState(
        getGameDataProperty(boardNumber, 'history') === null ? [] : JSON.parse(getGameDataProperty(boardNumber, 'history'))
    );
    let [selected, setSelected] = useState([]);
    const [submitted, setSubmitted] = useState(false);
    const [inSubmissionAnimation, setInSubmissionAnimation] = useState(false);

    const [showWarning, setShowWarning] = useState(false);
    const [warningIndex, setWarningIndex] = useState(0);
    const [warningRed, setWarningRed] = useState(false);

    const [currentBoxId, setCurrentBoxId] = useState();
    const [wasIncorrect, setWasIncorrect] = useState(false);
    const [cantSelect, setCantSelect] = useState(false);

    const [dateWhenStart, setDateWhenStarted] = useState(new Date());

    const [timeTaken, setTimeTaken] = useState(getGameDataProperty(boardNumber, 'timeTaken'));

    const [useTextMode, setUseTextMode] = useState(false)

    const [disablePlayOtherBoard, setDisablePlayOtherBoard] = useState(false);

    const [showGiveUpModal, setShowGiveUpModal] = useState(false)

    const [showHintsModal, setShowHintsModal] = useState(false)
    const [revealedHints, setRevealedHints] = useState([false, false, false, false])

    const inHOF = PLAYER_AMOUNT_PER_CATEGORY === 5

    useEffect(() => {
        shuffleGrid(playersRemaining)
        setDisablePlayOtherBoard(lives < LIVES_AMOUNT_MAIN && gameResult === gameResultTypes.playing)
        if (lives <= 0 && gameResult === gameResultTypes.lost) {
            endGameAnimation(gameResult);
        }
        // eslint-disable-next-line
    }, []);

    // const decideDisable = async (newGameResult, newLives, newSuccessfulGroups) => {
    //     if (newGameResult === gameResultTypes.playing && (newLives < LIVES_AMOUNT_MAIN || newSuccessfulGroups.length >= 1)) {
    //         setDisablePlayOtherBoard(true)
    //     }
    // }

    // // Run new board data came, update values
    // useEffect(() => {
    //     const newPlayersRemaining = getGameDataProperty(boardNumber, 'playersRemaining') === null ? player_data : JSON.parse(getGameDataProperty(boardNumber, 'playersRemaining'))
    //     const newLives = getGameDataProperty(boardNumber, 'lives') === null ? LIVES_AMOUNT_MAIN : Number(getGameDataProperty(boardNumber, 'lives'))
    //     const newGameResult = getGameDataProperty(boardNumber, 'gameResult') === null ? gameResultTypes.playing : getGameDataProperty(boardNumber, 'gameResult')
    //     const newSuccessfulGroups = getGameDataProperty(boardNumber, 'successfulGroups') === null ? [] : JSON.parse(getGameDataProperty(boardNumber, 'successfulGroups'))
    //     const newcategoryData = getGameDataProperty(boardNumber, 'categoryData') === null ? category_data : JSON.parse(getGameDataProperty(boardNumber, 'categoryData'))
    //     const newTimeTaken = getGameDataProperty(boardNumber, 'timeTaken')

    //     setPlayersRemaining(newPlayersRemaining)
    //     setGameResult(newGameResult)
    //     setSuccessfulGroups(newSuccessfulGroups)
    //     setLives(newLives)
    //     setHistory(getGameDataProperty(boardNumber, 'history') === null ? [] : JSON.parse(getGameDataProperty(boardNumber, 'history')))
    //     setCategoryData(newcategoryData)
    //     setTimeTaken(newTimeTaken)

    //     setCantSelect(false)
    //     deselectAll()
    //     setUseTextMode(false)

    //     // reset timer
    //     if (!showStartModal && (getGameDataProperty(boardNumber, 'gameResult') === null || getGameDataProperty(boardNumber, 'gameResult') === gameResultTypes.playing)) {
    //         handleStart();
    //     }
    //     decideDisable(newGameResult, newLives, newSuccessfulGroups)

    //     shuffleGrid(newPlayersRemaining)
    //     // eslint-disable-next-line
    // }, [boardNumber])

    useEffect(() => {
        if (!showStartModal && gameResult === gameResultTypes.playing) {
            handleStart(); // Start timer
        }
        // eslint-disable-next-line
    }, [showStartModal]);

    useEffect(() => {
        if (gameResult !== gameResultTypes.playing) setCantSelect(true);
    }, [gameResult]);

    const answerKey = generateAnswerKey(player_data);

    const handleStart = () => {
        if (lives < LIVES_AMOUNT_MAIN || successfulGroups.length > 0) return
        setDateWhenStarted(new Date())
    };

    // Get difference of time taken when started vs time ended
    const getTimeTaken = () => {
        const dateWhenFinished = new Date()
        const timeDiff = dateDifferenceInMiliSeconds(dateWhenStart, dateWhenFinished)
        setTimeTaken(timeDiff)
        setCurrentGameData(boardNumber, 'timeTaken', timeDiff)
        return timeDiff
    };

    const shuffleGrid = (givenPlayers) => {
        const shuffledSquares = getShuffledGrid(givenPlayers)
        setPlayersRemaining(shuffledSquares);
    };

    const deselectAll = () => {
        setSelected([]);
    };

    const showWarningFunc = async (index, red_warning, duration) => {
        showWarningFuncWithoutAsync(index, red_warning, duration)
        await timeout(duration);
    };

    const showWarningFuncWithoutAsync = (index, red_warning, duration) => {
        setWarningIndex(index);
        setWarningRed(red_warning);
        setShowWarning(true);
        setTimeout(() => setShowWarning(false), duration);
    };

    const submit = async () => {
        setCantSelect(true);
        setDisablePlayOtherBoard(true);
        setCurrentGameData(boardNumber, 'playersRemaining', JSON.stringify(playersRemaining));

        // Return true if the selected is allowed to submit
        const allowSubmission = () => {
            const selected_set = new Set(selected);
            const areSetsEqual = (a, b) =>
                a.size === b.size && [...a].every((value) => b.has(value));

            const checkHistory = () => {
                for (let i = 0; i < history.length; i++) {
                    const history_set = new Set(history[i]);

                    if (areSetsEqual(history_set, selected_set)) {
                        showWarningFuncWithoutAsync(1, true, 2000);
                        return false;
                    }
                }
                return true;
            };

            if (history.length <= 0) {
                return true;
            }
            return checkHistory();
        };

        const checkAnswer = () => {
            let correct = 0;
            setHistory([...history, [...selected]]);
            setCurrentGameData(boardNumber, 'history', JSON.stringify([...history, [...selected]]));

            for (let i = 0; i < PLAYER_AMOUNT_PER_CATEGORY; i++) {
                for (let j = 0; j < PLAYER_AMOUNT_PER_CATEGORY; j++) {
                    if (answerKey[i].includes(selected[j])) {
                        correct++;
                    }
                }
                if (correct === PLAYER_AMOUNT_PER_CATEGORY) return 0; // An answer
                else if (correct === PLAYER_AMOUNT_PER_CATEGORY - 1) return 1; // Almost an answer
                correct = 0;
            }
            return 2; // Way off
        };

        const allowed_submission = allowSubmission();
        if (!allowed_submission) {
            setCantSelect(false);
            return;
        }

        const submitQueue = [...selected];

        // Go through every player selected and move their picture
        for (let i = 0; i < PLAYER_AMOUNT_PER_CATEGORY; i++) {
            setCurrentBoxId(submitQueue[i]);
            setSubmitted(true);
            setTimeout(() => setSubmitted(false), 200);
            await timeout(200);
        }
        await timeout(800);

        const checkedAnswer = checkAnswer();
        if (checkedAnswer === 0) {
            successfulAnswer();
            setCurrentGameData(boardNumber, 'lives', lives);
            if (playersRemaining.length <= PLAYER_AMOUNT_PER_CATEGORY) endGame(true);
        } else {
            setWasIncorrect(true);
            setTimeout(() => setWasIncorrect(false), 800); // Set a timeout to stop shaking after 1 second
            await timeout(800); // for 1 sec delay

            if (!inHOF) {
                setLives(lives - 1);
                setCurrentGameData(boardNumber, 'lives', lives - 1);
                if (lives <= 1) {
                    setInSubmissionAnimation(false);
                    endGame(false);
                    return;
                }
            }

            // Show one away
            if (checkedAnswer === 1) {
                await showWarningFunc(3, false, 2000);
            }
        }
        setInSubmissionAnimation(false);
        setCantSelect(false);
    };

    const selectPlayer = (playerid) => {
        if (selected.includes(playerid)) {
            // Remove the player if he was already selected
            setSelected(selected.filter((id) => id !== playerid));
        } else if (selected.length >= PLAYER_AMOUNT_PER_CATEGORY) {
            !inHOF ?
                showWarningFuncWithoutAsync(0, true, 2000) : showWarningFuncWithoutAsync(3, true, 2000);
            return;
        } else {
            // Add player to selected array
            setSelected([...selected, playerid]);
        }
    };

    const successfulAnswer = () => {
        setSuccessfulGroups([
            ...successfulGroups,
            [...selected],
        ]);
        setCurrentGameData(boardNumber,
            'successfulGroups',
            JSON.stringify([
                ...successfulGroups,
                [...selected],
            ])
        );
        const newPlayerIds = [...playersRemaining].filter(
            (player) => !selected.includes(player['PlayerId'])
        );
        setPlayersRemaining(newPlayerIds);
        setCurrentGameData(boardNumber, 'playersRemaining', JSON.stringify(newPlayerIds));
        deselectAll();
    };

    const renderlives = () => {
        let livesComponents = [];
        for (let i = 0; i < lives; i++) {
            livesComponents.push(
                <img
                    key={i}
                    style={{ height: league_life_icon_size, width: league_life_icon_size }}
                    src={league_favicon}
                    alt="basketball"
                />
            );
        }
        return livesComponents;
    };

    const renderPlayers = () => {
        const squares = playersRemaining.map((player, i) => (
            <PlayerTile
                key={i}
                cantSelect={cantSelect}
                playerInfo={player}
                playerId={player['PlayerId']}
                selected={selected}
                selectFunction={selectPlayer}
                doShakeUp={submitted && currentBoxId === player['PlayerId']}
                doShakeSide={wasIncorrect && selected.includes(player['PlayerId'])}
                groupNumber={successfulGroups.length}
                useTextMode={useTextMode}
                inHOF={inHOF}
            />
        ));

        const groupsRemaining = playersRemaining.length / PLAYER_AMOUNT_PER_CATEGORY;
        const rows = Array.from({ length: PLAYER_AMOUNT_PER_CATEGORY }, (_, i) => (
            <div key={i} className="row">
                {squares.slice(i * groupsRemaining, i * groupsRemaining + groupsRemaining)}
            </div>
        ));

        return rows;
    };

    const endGameAnimation = async (result) => {
        let delayWait = 0;
        deselectAll();
        setDisablePlayOtherBoard(true)

        if (result === gameResultTypes.lost) {
            const solvedGroups = findRemainingGroups(successfulGroups, player_data);
            const unsolvedGroups = Array.from({ length: PLAYER_AMOUNT_PER_CATEGORY }, (_, index) => index + 1).filter(
                (group) => !solvedGroups.includes(group)
            );
            delayWait = unsolvedGroups.length * 2000;

            const addUnsolvedGroups = async () => {
                let successfulGroupNeedtoAdd = [];
                await showWarningFunc(2, true, 2000);

                for (const group of unsolvedGroups) {
                    setSuccessfulGroups((prevGroups) => [
                        ...prevGroups,
                        playersRemaining.filter((player) => player['GroupId'] === group).map(player => player.PlayerId),
                    ]);

                    successfulGroupNeedtoAdd.push(
                        playersRemaining.filter((player) => player['GroupId'] === group).map(player => player.PlayerId),
                    );

                    setPlayersRemaining((prevRemaining) =>
                        prevRemaining.filter((player) => player['GroupId'] !== group)
                    );

                    await new Promise((resolve) => setTimeout(resolve, 2000));
                }
                setCurrentGameData(boardNumber,
                    'successfulGroups',
                    JSON.stringify(successfulGroups.concat(successfulGroupNeedtoAdd))
                );
                setCurrentGameData(boardNumber, 'playersRemaining', JSON.stringify([]));
            };
            addUnsolvedGroups();
            await timeout(2000); // for 2 sec delay
        } else if (result === gameResultTypes.win) {
            if (lives === LIVES_AMOUNT_MAIN) showWarningFunc(0, false, 2000);
            else if (lives === 1) showWarningFunc(2, false, 2000);
            else showWarningFunc(1, false, 2000);

            await new Promise((resolve) => setTimeout(resolve, 2000));
        }

        setTimeout(() => {
            setViewingResults(true);
            setGameResult(result);
            setCurrentGameData(boardNumber, 'gameResult', result);
            setDisablePlayOtherBoard(false);
        }, delayWait);
    };

    const endGame = async (wonGame) => {
        const result = wonGame ? gameResultTypes.win : gameResultTypes.lost;
        setGameResult(result);
        const timeFinal = getTimeTaken();
        setCurrentGameData(boardNumber, 'gameResult', result);

        // PREPARE FINAL DATA TO CHECK
        const final_object = await prepareBoardFinal(result, timeFinal);
        try {
            // SUBMIT DATA
            if (inHOF) {
                await finalizeHOFBoardAnswers(final_object)
            }
            else await finalizeBoardAnswers(final_object);

            window.gtag('event', 'submitted_game', {
                'gameResult': wonGame ? gameResultTypes.win : gameResultTypes.lost
            })
            endGameAnimation(result);
        }
        catch (e) {
            endGameAnimation(result);
        }
    };

    const prepareBoardFinal = (result, timeFinal) => {
        const final_history = serializedHistory(history.concat([selected]))
        const final_check_object = {
            NumLivesLeft: result === gameResultTypes.lost ? inHOF ? 0 : lives - 1 : lives,
            History: inHOF ? history.length.toString() : final_history,
            BoardNumber: boardNumber,
            UserId: userId,
            TimeZone: userTimezone.toString(),
            UserAgent: userAgent,
            IPAddress: userIpAddress,
            UserBrowser: userBrowser,
            TimeTaken: timeFinal,
        };

        return final_check_object;
    };

    const giveUp = () => {
        setLives(0);
        setInSubmissionAnimation(false);
        endGame(false);
    }

    const functions = [
        { title: 'Hints', func: () => setShowHintsModal(true) },
        { title: 'Shuffle', func: () => shuffleGrid(playersRemaining) },
        { title: 'Deselect All', func: deselectAll },
        { title: 'Submit', func: submit },
    ];

    return (
        <div className='main-container' style={{ color: inHOF ? 'white' : 'black' }}>
            <div className="container">
                <Header boardNumber={boardNumber} switchBoard={switchBoard} boardStatsGlobal={boardStatsGlobal} setBoardStatsGlobal={setBoardStatsGlobal} setStartModal={setStartModal} setShowAnalyticsPanel={setShowAnalyticsPanel} disablePlayOtherBoard={disablePlayOtherBoard} inHOF={inHOF} setShowBoardSelector={setShowBoardSelector} playDate={playDate} />
                {
                    showWarning ? <WarningModal warningStringIndex={warningIndex} is_red_warning={warningRed} /> : <div style={{ height: '5vh' }} />
                }

                {successfulGroups.map((group, i) => (
                    <SuccessfulCategoryTile key={i} category_data={category_data} player_data={player_data} group={group} useTextMode={useTextMode} />
                ))}

                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    {renderPlayers()}
                </div>

                {gameResult !== gameResultTypes.playing ?
                    <>
                        <Button
                            title="View Results"
                            func={setViewingResults}
                            style={{ marginTop: '1em' }}
                        />
                        {!inHOF && boardNumber !== Number(localStorage.getItem('todaysBoardNumber')) && <ShowBasicAnalytics boardStatsGlobal={boardStatsGlobal} setBoardStatsGlobal={setBoardStatsGlobal} boardNumber={boardNumber} setShowAnalyticsPanel={setShowAnalyticsPanel} />}
                    </>
                    :
                    <>
                        {!inHOF &&
                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                <p style={{ fontSize: '.6em', marginRight: '.2em' }}>Mistakes Remaining: </p>
                                {renderlives()}
                            </div>}
                        <div style={{ display: 'flex', flexDirection: 'row', marginBlock: '1vh' }}>
                            {
                                functions.map((obj, i) => (
                                    <Button key={i}
                                        title={obj.title}
                                        func={obj.func}
                                        disabled={(obj.title === 'Submit' && selected.length < PLAYER_AMOUNT_PER_CATEGORY)
                                            || (obj.title === 'Deselect All' && selected.length < 1)
                                            || inSubmissionAnimation || cantSelect
                                        }
                                    />
                                ))
                            }
                        </div>
                        {inHOF && <Button style={{ marginTop: '1em' }} title={'Give Up'} func={() => setShowGiveUpModal(true)} disabled={inSubmissionAnimation || cantSelect} />}
                    </>
                }
                <UseNameToggle useTextMode={useTextMode} setUseTextMode={setUseTextMode} />

            </div>
            {
                showGiveUpModal &&
                <GiveUpModal
                    setShowGiveUpModal={setShowGiveUpModal}
                    giveUpFunc={giveUp}
                    showHintFunc={setShowHintsModal}
                />
            }
            {
                viewingResults &&
                <EndModal
                    player_data={player_data}
                    history={history}
                    gameResult={gameResult}
                    setViewingResults={setViewingResults}
                    lives={lives}
                    boardNumber={boardNumber}
                    secondsTaken={timeTaken}
                    switchBoard={switchBoard}
                    setShowAnalyticsPanel={setShowAnalyticsPanel}
                    inHOF={inHOF}
                />
            }
            {
                showHintsModal &&
                <HintsModal
                    setShowHintsModal={setShowHintsModal}
                    category_data={category_data}
                    revealedHints={revealedHints}
                    setRevealedHints={setRevealedHints}
                />
            }
        </div>
    );
}

export default Main;
