import React from 'react';
import './stylesheets/SmallAnnoucementModal.css';
import { MAIN_BACKGROUND_COLOR_DARKER_MAP } from '../../siteConstants';
import X_ICON from '../../assets/x.png'
import { getCurrentLeague } from '../../helperFunctions';
// import { useNavigate } from "react-router-dom";

const current_league = getCurrentLeague();

const SmallAnnoucementModal = (props) => {
    const { setSmallAnnoucementModal } = props;
    // const navigate = useNavigate();

    return (
        <div className='main-feature-container' style={{ backgroundColor: MAIN_BACKGROUND_COLOR_DARKER_MAP[current_league] }}>
            <div className='close-icon' />
            {/* <div className='text-container' onClick={() => { navigate("/hof"); window.location.reload() }}> */}
            <div className='text-container'>

                {/* <p className='feature-text'>Click me to try out NFL x LeConnections now!</p> */}
                <a className='feature-text' href={'https://nf.leconnections.app'} target="_blank" rel="noopener noreferrer">🏈 Click me to try out NFL x LeConnections now! 🏈</a>

            </div>
            <button className='close-button' onClick={() => setSmallAnnoucementModal(false)}>
                <img className='close-icon' src={X_ICON} alt='close'></img>
            </button>
        </div>
    )
}


export default SmallAnnoucementModal;