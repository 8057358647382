import React from 'react';
import '../stylesheets/Ads.css'
import FreestarAdSlot from '@freestar/pubfig-adslot-react-component'

const VerticalAd2 = () => {

    return (
        <div className='vertical-ad-container'>
            <FreestarAdSlot
                publisher={'leconnections-app'}
                placementName={'leconnections_sidewall_right'}
            />
        </div>

    );
};

export default VerticalAd2;