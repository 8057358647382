import BasketballFavicon from './assets/nba/basketball.svg';
import FootballFavicon from './assets/nfl/football-life.png';
import SoccerFavicon from './assets/epl/soccer-favicon.png';

import BasketballSpinner from './assets/nba/basketball-spinner.png'
import FootballSpinner from './assets/nfl/football-spinner.png'
import SoccerSpinner from './assets/epl/soccer-spinner.png'

const LIVES_AMOUNT_MAIN = 4

const DIFFICULTY_COLOR_DATA = [
    'Easy', 'Medium', 'Hard', 'All Star ⭐', 'Hall of Fame'
]

export const CURRENT_LEAGUE_MAP = {
    nba: 'NBA',
    nfl: 'NFL',
    epl: 'EPL',
}

export const CURRENT_LEAGUE_NAME_MAP = {
    nba: 'LeConnections',
    nfl: 'NFLeConnections',
    epl: 'EPLeConnections',
}

export const CURRENT_LEAGUE_FAVICON_MAP = {
    nba: BasketballFavicon,
    nfl: FootballFavicon,
    epl: SoccerFavicon,
}

export const CURRENT_LEAGUE_ICON_SPINNER_MAP = {
    nba: BasketballSpinner,
    nfl: FootballSpinner,
    epl: SoccerSpinner,
}

export const CURRENT_LEAGUE_LIFE_ICON_SIZE_MAP = {
    nba: '1em',
    nfl: '1.2em',
    epl: '0.9em',
}

export const CURRENT_LEAGUE_URL_MAP = {
    nba: 'www.leconnections.app',
    nfl: 'www.nf.leconnections.app',
    epl: 'www.ep.leconnections.app',
}

export const MAIN_BACKGROUND_COLOR_MAP = {
    nba: '#f9f9f9',
    hof: '#0D0D0D',
    nfl: '#a8cbe0',
    epl: '#f9f9f9',
}

export const MAIN_BACKGROUND_COLOR_DARKER_MAP ={
    nba: '#d9d9d9',
    nfl: '#d9d9d9',
    epl: '#d9d9d9',
}

export const CATEGORY_COLOR_DATA_MAP = {
    nba: ['#face3e', '#E47041', '#bf4947', '#141414', '#1c1c84'],
    nfl: ['#e3e3e3', '#1b2b5e', '#ad1d1d', '#141414', '#0a3324'],
    epl: ['#ded559', '#41d1a7', '#3ecfcf', '#340040', '#2C2C90']
}

const COLOR_EMOJIS = {
    '#face3e': '🟨',
    '#E47041': '🟧',
    '#bf4947': '🟥',
    '#141414': '⬛️',
    '#1c1c84': '🟦',
    '#e3e3e3': '⬜️',
    '#1b2b5e': '🟦',
    '#ad1d1d': '🟥',
    '#0a3324': '🟩',
    '#ded559': '🟡',
    '#41d1a7': '🟢',
    '#3ecfcf': '🔵',
    '#340040': '🟣',
}

export const PLAYER_TILE_COLOR_UNSELECTED_MAP = {
    nba: '#DDDDDB',
    nfl: '#deeffc',
    epl: '#DDDDDB',
}

export const PLAYER_TILE_COLOR_SELECTED_MAP = {
    nba: '#8C8989',
    nfl: '#56798f',
    epl: '#8C8989',
}

export const LEAGUE_GOAT_MAP = {
    nba: 'Lebron',
    nfl: 'Brady',
    epl: 'Ronaldo',
}

const DARK_COLORS_WHITE_TEXT = ['#141414', "#1c1c84", '#1b2b5e', '#ad1d1d', '#340040', '#0a3324']

const HOF_MAIN_BACKGROUND_COLOR = '#0D0D0D'
const GREY_BUTTON_BACKGROUND_COLOR = '#DDDDDB'

const START_MODAL_BACKGROUND_COLOR = '#303030'
const END_MODAL_BACKGROUND_COLOR = '#303030'

const HOF_PLAYER_TILE_COLOR_UNSELECTED = 'grey'
const HOF_PLAYER_TILE_COLOR_SELECTED = '#3b3b3b'

const WARNING_COLOR_RED= '#c22231'
const WARNING_COLOR_GREEN= '#56AE57'

const WARNING_STRINGS_RED = ['Cannot select more than four players', 'Already selected that group', 'Nice Try', 'Cannot select more than five players']
const WARNING_STRINGS_GREEN = ['Perfect', 'Great Job', 'Clutch', 'One Away']

export {LIVES_AMOUNT_MAIN, START_MODAL_BACKGROUND_COLOR, GREY_BUTTON_BACKGROUND_COLOR, END_MODAL_BACKGROUND_COLOR, WARNING_COLOR_RED, WARNING_COLOR_GREEN, WARNING_STRINGS_RED, WARNING_STRINGS_GREEN
, COLOR_EMOJIS, DIFFICULTY_COLOR_DATA, HOF_PLAYER_TILE_COLOR_SELECTED, HOF_PLAYER_TILE_COLOR_UNSELECTED, HOF_MAIN_BACKGROUND_COLOR, DARK_COLORS_WHITE_TEXT};