export const privacy_policy = [
    {
        label: '1. Information We Collect',
        descs: ['Personal Data: We do not collect any personal data from our users. Personal data refers to any information that can directly or indirectly identify an individual, such as name, email and address.',
        'Non-Personal Data: We do collect some non-personal data, such as IP addresses and device names, for analytical purposes. This information helps us understand how users interact with our website and improves our services. We assure you that this data is used solely for internal purposes and is not shared with any third parties.']
    },
    {
        label: '2. Use of Local Storage',
        descs: ['Local Storage: LeConnections uses local storage to enhance your gaming experience. Local storage stores text on your device when you visit a website. We use cookies to store personal streaks and win rates, allowing you to track your progress and achievements within the game!',
        'If you prefer not to use local storage, you may adjust your browser settings to disable them. However, please note that disabling cookies may affect the functionality of certain features within LeConnections.']
    },{
        label: '3. Third-Party Links',
        descs: ['External Links: LeConnections may work with some third-party advertising companies to deliver ads when you play our game. We encourage you to review the privacy policies of these agencies: Google Adsense and Freestar.']
    },{
        label: '4. Changes to this Privacy Policy',
        descs: ['Updates: We reserve the right to update or revise this Privacy Policy at any time. Any changes will be effective immediately upon posting the revised Privacy Policy on this page. We ask you to review this Privacy Policy periodically to stay informed about how we are protecting your information.']
    },{
        label: '5. Contact Us',
        descs: ['Questions or Concerns: If you have any questions or concerns about our Privacy Policy or the way we handle your personal information, please contact us at leconnectionsapp@gmail.com.',
    'By using LeConnections, you signify your acceptance of this Privacy Policy. If you have a problem with this Privacy Policy, please contact us!', 'Thank you for your trust in LeConnections, and we hope you enjoy our game!']
    },
]