import React, { useEffect, useRef } from 'react';
import './stylesheets/InstructionModal.css';
import { GREY_BUTTON_BACKGROUND_COLOR, START_MODAL_BACKGROUND_COLOR } from '../../siteConstants';

const BigAnnouncementModal = (props) => {
    const { setStartModal } = props;
    const btnRef = useRef(null);

    useEffect(() => {
        const clickedOutside = (e) => {
            if (e.srcElement.className === 'start-modal') setStartModal(false)
        }
        document.body.addEventListener('click', clickedOutside)
        return () => document.body.removeEventListener('click', clickedOutside)
    })

    return (
        <div ref={btnRef} className='start-modal'>
            <div className='modal-content' style={{ backgroundColor: START_MODAL_BACKGROUND_COLOR }}>
                <h2 className='modal-title'>
                    <b style={{ color: 'crimson' }}>NFLConnections</b> is here!
                </h2>
                <h4 style={{ fontSize: '1.4em' }}>Hey LeConnections fans! </h4>
                <h4 style={{ fontSize: '1em' }}>After months of feedback and consideration, we decided to expand the fun to other leagues!</h4>
                <h4 style={{ fontSize: '1em' }}>Starting with the most <b style={{ color: 'lightgreen' }}>requested</b> league: The <b style={{ color: 'crimson' }}> NFL</b></h4>

                <br></br>

                {/* <h4 style={{ fontSize: '1em' }}>For the <b style={{ color: 'lightgreen' }}>next 100 hours</b> you will be able to go back and play ANY previous board</h4>

                <h4 style={{ fontSize: '1em' }}>Replay any boards you've missed, ranging from our very first board, to our April Fools one!</h4>

                <h4 style={{ fontSize: '1em' }}>Reminder that boards were made specifically for that date, so some categories might be inaccurate at this point.</h4>
                <br></br>

                <h4 style={{ fontSize: '1em' }}>We hope you enjoy this feature and <b style={{ color: 'lightgreen' }}>thank you again</b> for your continued support!</h4>

                <h4 style={{ fontSize: '1em' }}>NOTE: Board #100 is a JOKE board. Please don't take it seriously :) We recommend you use the player name feature!</h4> */}

                <h4 style={{ fontSize: '1.4em' }}>Try out NFL x LeConnections now!</h4>

                <a style={{ fontSize: '1.8em', color: '#5499ff', alignSelf: 'center' }} href={'https://nf.leconnections.app'} target="_blank" rel="noopener noreferrer">nf.leconnections.app</a>

                <br></br>

                <div className='get-started-container'>
                    <button className='started-button' style={{ backgroundColor: GREY_BUTTON_BACKGROUND_COLOR }} onClick={() => setStartModal(false)} >
                        <p className='started-button-label'>
                            Continue
                        </p>
                    </button>
                </div>
            </div>
        </div>
    )
}


export default BigAnnouncementModal;
