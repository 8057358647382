import React from 'react';
import Toggle from 'react-toggle'
import './board_stylesheets/UseNameToggle.css';

const UseNameToggle = (props) => {
    const { useTextMode, setUseTextMode } = props
    return (
        <div className='name-toggle-container'>
            <p className='name-togle-label-text'>Use Player Name</p>
            <Toggle
                defaultChecked={useTextMode}
                onChange={() => { setUseTextMode(!useTextMode) }} />
        </div>
    )
}

export default UseNameToggle;
