import React from 'react';
import instagramIcon from '../assets/instagram-bw.png';
import twitterIcon from '../assets/twitter-bw.avif';
import './stylesheets/footer.css'
import { useNavigate } from "react-router-dom";
import { getCurrentLeague } from '../helperFunctions';
import { CURRENT_LEAGUE_MAP, CURRENT_LEAGUE_NAME_MAP, CURRENT_LEAGUE_URL_MAP } from '../siteConstants';

const current_league = getCurrentLeague();
const current_league_name = CURRENT_LEAGUE_MAP[current_league];
const league_title = CURRENT_LEAGUE_NAME_MAP[current_league];
const league_url_name = CURRENT_LEAGUE_URL_MAP[current_league];

const Footer = (props) => {
    const { inHOF } = props;
    const navigate = useNavigate();

    const medias_array = [
        {
            name: 'Instagram',
            icon: instagramIcon,
            link: 'https://www.instagram.com/leconnections/'
        },
        {
            name: 'X',
            icon: twitterIcon,
            link: 'https://twitter.com/leconnectapp'
        },
    ]

    return (
        <div className='footer-container' style={{ color: inHOF ? 'white' : 'black' }}>
            <div className='donation-container'>
                {/* <a href={league_url_name} target="_blank" rel="noopener noreferrer">
                    <p className='footer-text'> Play {league_title}</p>
                </a> */}
                <p className='media-text'>Follow us for daily<br />updates and reminders!</p>
                <div className='medias-container'>
                    {medias_array.map((media, i) => (
                        <a href={media.link} key={i} target="_blank" rel="noopener noreferrer">
                            <img className={`single-link ${inHOF && 'invert-100-percent'}`} src={media.icon} alt={media.name} />
                        </a>
                    ))}
                </div>
            </div>

            <div className='footer-text-container'>
                {/* <div>
                    <p className='footer-text'>Help us out with a <br />
                        <a href={'https://www.venmo.com/u/leconnections'} target="_blank" rel="noopener noreferrer">Venmo</a> donation!
                    </p>
                </div> */}
                <a href={'https://forms.gle/4bmjQJiLRARXvLjJ6'} target="_blank" rel="noopener noreferrer">
                    <p className='footer-text'>Have feedback/comments?</p>
                </a>
                {current_league === 'nba' ?
                    <p className='footer-text'>*All pictures belong to the {current_league_name} or ESPN</p> :
                    <><br /><br /></>
                }

                <a className='footer-text' style={{ cursor: 'pointer', fontWeight: 'bolder' }} onClick={() => { navigate("/privacy-policy") }}>Privacy Policy</a>
                <button className='footer-text' id="pmLink">Privacy Manager</button>

            </div>
        </div>
    )
}

export default Footer;
